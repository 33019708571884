.react-multi-carousel-dot button {
  color: white !important;
  border-color: aliceblue !important;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

.react-tel-input .form-control {
  @apply !w-full py-6 !text-base;
}

.opt-container {
  @apply flex justify-between gap-2;
}
.opt-container input {
  @apply !mr-0 flex-1 py-5 outline-none;
}

