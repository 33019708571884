@import url("https://fonts.googleapis.com/css2?family=M6ontserrat&family=Poppins:wght@100;200;300;400&family=Roboto&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap");
body {
  font-family: NotoSansRegular "Poppins", sans-serif;
  /* font-family: NotoSansRegular, sans-serif; */
}
.custom-toast {
  width: 20% !important;
}

.addnew {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: #33373d;
}
.fnt12 {
  font-size: 10px;
  color: white !important;
}
.fnt10 {
  font-size: 10px;
}
.fnt14 {
  font-size: 12px;
  font-weight: bold;
}
.bg-mg {
  background-color: black;
}
.text_White {
  color: white !important;
}
.text_light {
  color: whitesmoke;
}
.dicription {
  font-size: 16px;
  color: #aaaaaa;
  font-weight: bold;
}
.btn-close {
  display: none !important;
}
.fnt30 {
  font-size: 25px !important;
}
.titl {
  font-size: 13px;
}
.fnt35 {
  font-size: 45px !important;
}
a {
  text-decoration: none !important;
  color: white !important;
}
.list_inner {
  line-height: 20px;
  color: white;
  background-color: #000000c7 !important;
}
.list_inner:hover {
  cursor: pointer;
}

.searchicon {
  font-weight: bolder;
  font-size: 35px;
  text-align: center;
  cursor: pointer;
  position: absolute;
  right: 7%;
  bottom: 23%;
  color: white;
}

.playlist::-webkit-scrollbar {
  width: 5px;
  display: none;
}

.playlist::-webkit-scrollbar-thumb {
  background-color: #808080;
}

.playlist::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}
.resumeicn {
  color: rgba(128, 128, 128, 0.53);
}
.resumeicn:hover {
  color: white;
}
.playlist {
  height: 60vh;
  overflow-y: auto;
}
.playlist_con {
  position: relative;
  background-color: rgba(82, 80, 80, 0.32);
  z-index: 99;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  background-color: rgba(20, 19, 20, 0);
}

.playlist_con:hover {
  border: 2px solid rgba(128, 128, 128, 0.32);
}
.playlist:hover::-webkit-scrollbar {
  display: block;
}
.inputfi {
  background-color: #000000c7 !important;
  border-radius: 100px !important;
  width: 0px !important;
  padding: 10 !important;
  transition: width 0s ease, padding 0s ease-in-out !important;
  position: relative !important;
}

.inputfi.collapsed {
  width: 250px !important;
  padding: 5px !important;
  border-radius: 100px !important;
}

.activeWathced {
  border-bottom: 2px solid white;
}
.cursor {
  cursor: pointer;
}

.index_size {
  font-size: 100px;
  font-weight: bolder;
}
.iconss {
  font-size: 35px !important;
  position: relative;
  z-index: 10;
}
.profilepage {
  height: 0px;
  width: 300px !important;
  overflow: hidden;
  transition: width 0.9s ease;
}

.profilepage.expanded {
  height: 100vh;
  background-color: #191e25;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  margin: auto;
}
.radius_1 {
  border-radius: 25px !important;
  background-color: #0000004a !important;
  border: 1px solid white !important;
  box-shadow: none !important;
  padding: 2px !important;
}
.form-select {
  /* color: white !important; */
  font-size: 13px !important;
  padding-left: 8px !important;
  background-color: #0000004a !important;
}
.footer {
  height: 50vh;
}
.slide {
  height: 70vh;
  background-size: cover;
  object-fit: cover;
  width: 100%;
  border-radius: 5px;
}
.disabled {
  display: none;
}
.button1,
.button2 {
  position: absolute;
  top: 40%;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
.button2 {
  right: 1%;
}
.slider-container {
  position: relative;
  height: 70vh;
}
.textsiz {
  font-weight: 700;
}

.form-select {
  --bs-form-select-bg-img: url("../public/NewImg/icons8-expand-arrow-26.png") !important;
}
.overlaybn {
  position: absolute;
  top: 10%;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.596);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(254, 250, 250, 0) 62%
  );
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.973) 34%,
    rgba(0, 0, 0, 0.527) 69%,
    rgba(255, 255, 255, 0) 100%
  );
  pointer-events: none !important;
}

.watchmovie-container {
  position: relative;
  width: 100%;
}

.watchmovie-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.973) 34%,
    rgba(0, 0, 0, 0.527) 69%,
    rgba(255, 255, 255, 0) 100%
  );
}

.logo-container {
  position: absolute;
  top: 60%;
  cursor: pointer;
  left: 10%;
  opacity: 0;
  animation: fade-in 0.9s ease-in-out forwards,
    slide-up 0.3s ease-in-out 0.9s forwards;
  transition: top 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

@keyframes slide-up {
  to {
    top: 50%;
    opacity: 1;
  }
}
.playicon {
  font-size: 70px !important;
  transition: transform 0.9s;
}

.playicon:hover,
.playicon1:hover {
  transform: scale(1.2);
  color: rgba(241, 235, 235, 0.808);
}
.iconmargin {
  margin: 13px 0px 0px 2px !important;
}

.watchlist,
.detailsList {
  background-color: #33373d;
  transition: transform 0.9s;
  height: 50px !important;
  width: 50px !important;
  border-radius: 100px;
}
.slidewatch,
.viewmore {
  background-color: #33373d;
  transition: transform 0.9s;
  height: 30px !important;
  width: 30px !important;
  border-radius: 100px;
}
.watch1 {
  border: none;
  display: none;
  width: 100px !important;
  position: absolute;
  top: 112%;
  border-radius: 5px;
  left: -26% !important;
}
.watchlist:hover > .watch1,
.watchlist:hover + .sliderwatch1s {
  display: block;
}

.watchlist:hover,
.slidewatch:hover,
.viewmore:hover,
.detailsList:hover {
  transform: scale(1.2) translateY(0);
  background-color: white !important;
  border-radius: 100px;
  color: black !important;
}
.viewtrailer {
  transform: scale(1.2) translateY(0);
  border-radius: 100px;
  color: black !important;
}
.details,
.sliderwatch1,
.slidemore,
.details1,
.sliderwatch1s {
  border: none;
  display: none;
  width: max-content !important;
  position: absolute;
  top: 90%;
  border-radius: 5px;
  color: black !important;
  left: 15%;
  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  opacity: 1;
  transform: translateY(10px);
}
.party {
  left: -19%;
}
.downlaod {
  left: -50%;
}
.sliderwatch1s {
  left: 52%;
}
.details1 {
  left: 72%;
}
.carousel-indicators [data-bs-target] {
  height: 8px !important;
  width: 8px !important;
  border-radius: 50% !important;
}
.videoBNr .carousel-indicators [data-bs-target] {
  display: none;
}
.home .carousel-control-prev-icon,
.home .carousel-control-next-icon {
  display: none !important;
}
.button2,
.button1 {
  font-size: 20px !important;
  transition: background-color 0.3s, font-size 0.3s !important;
}

.button1:hover,
.button2:hover {
  background-color: rgba(183, 68, 68, 0.322) !important;
  font-size: 29px !important;
}
.movie-container {
  position: relative;
  transition: transform 0.9s ease;
  flex-direction: row !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: scale(1.1);
  transition: transform 3s 2000ms;
  top: -30px;
  opacity: 1;
  z-index: 2;
}

.data_container {
  display: grid;
  grid-template-columns: repeat(4, auto);
}
.movie-container:hover {
  transform: scale(1.2);
  z-index: 10;
}
.additional-content {
  display: none !important;
  background-color: rgb(11, 10, 10);
  transition: opacity 0.9s ease;
  position: relative;
  background: linear-gradient(rgba(27, 37, 48, 0), #1b2530 48px);
  padding: 80px 90px;
  top: 183%;
  margin-top: -145px;
  z-index: 999 !important;
  text-decoration: none;
}

.borderRa {
  border-radius: 10px;
  transition: transform 0.9s ease-in-out;
}
.slidebtn1,
.slidebtn2,
.contine2,
.contine2,
.slidebtn3,
.skip,
.custom-time-bar,
.contine {
  width: 20px !important;
  height: 100px;
  position: absolute;
  top: 30%;
  z-index: 13;
  border: none !important;
  background-color: rgba(255, 0, 0, 0);
  transition: background-color 0.9s, font-size 0.9s;
}
.contine,
.contine2 {
  top: 10%;
}
.skip {
  left: 50%;
}
.custom-time-bar {
  width: 80%;
}
.slidebtn3 {
  position: absolute;
  top: 15%;
}

.slidebtn1,
.contine {
  left: 1%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.movibtn {
  top: 30%;
}
.slidebtn2,
.contine2 {
  right: 1%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.slidebtn1:hover,
.contine:hover,
.slidebtn2:hover,
.contine2:hover {
  background-color: rgba(158, 149, 149, 0.283);
  margin: auto;
}
.addicon {
  position: absolute;
  top: 10%;
  right: 8%;
}
.addico {
  position: absolute;
  top: -6%;
  right: 0%;
}
.addicons {
  position: absolute;
  top: 28%;
  right: 22%;
}
.relativeP {
  position: relative !important;
}
.leftime {
  color: gray !important;
}
a {
  color: gray !important;
}
.sliderwatch1 {
  color: black !important;
}

.slidewatch:hover .sliderwatch1,
.movie-container:hover + .additional-content,
.detailsList:hover .details,
.detailsList:hover + .details1,
.viewmore:hover .slidemore {
  display: block !important;
}

.Apps {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-align: center;
}

.containerdd {
  width: 98%;
  display: flex;
  margin: auto;
}

.cardss {
  position: relative;
  flex: 1;
  overflow: hidden;
  transition: flex 0.5s, height 0.5s;
  cursor: pointer;
  border-radius: 10px;
  margin: 10px;
}
.copylink {
  color: black !important;
  border: none !important;
  font-weight: bold !important;
  background-color: white !important;
  transition: scaleY(0) background-color 3s;
}

.copylink:hover {
  transform: scaleY(1);
  background-color: rgb(240, 239, 239) !important;
}

.cardss img {
  object-fit: cover;
}

.cardss:hover {
  flex: 3;
}

.logo-container1 {
  z-index: 2;
  position: absolute;
  top: 60%;
  display: none !important;
  cursor: pointer;
  left: 10%;
  opacity: 0;
  animation: fade-inn 0.9s ease-in-out forwards,
    slide-upp 0.8s ease-in-out 0.9s forwards;
  transition: top 0.9s ease-in-out, opacity 0.9s ease-in-out;
}

@keyframes fade-inn {
  to {
    opacity: 1;
  }
}

@keyframes slide-upp {
  to {
    top: 30%;
    opacity: 1;
  }
}
.Orignal_seriesoverlays,
.toptenmovieOverl {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.331) 34%,
    rgba(0, 0, 0, 0.495) 69%,
    rgba(50, 46, 46, 0.553) 100%
  );

  z-index: 1;
  pointer-events: none !important;
}
.toptenmovieOverl {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.349) 34%,
    rgba(250, 244, 244, 0) 69%,
    rgba(254, 253, 253, 0) 100%
  );
  pointer-events: none !important;
}
.extraindex {
  position: relative;
  z-index: 99 !important;
}

.Orignal_seriesoverlays {
  display: none;
}

.movie-container:hover > .borderRa {
  border-radius: 10px 10px 0px 0px !important;
}
.cardss:hover .card-image + .logo-container1,
.cardss:hover .card-image + .overlays,
.cardss:hover .Orignal_seriesoverlays {
  display: block !important;
}

.progress__bar {
  position: relative;
}

.progress__watched {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  transition: width 0.3s ease;
}
.textbold {
  font-weight: bold;
}
.controls,
.volumes,
.bnrvolume {
  position: absolute;
  top: 93%;
}
.volumes,
.bnrvolume {
  top: 55%;
  z-index: 10;
  right: 5%;
  color: white;
}
.bnrvolume {
  top: 15%;
  right: 0%;
}

.duration {
  color: #8a8984;
  font-weight: bold;
}
.categorydropdown,
.profileviw {
  position: absolute;
  background-color: #191e25;
  min-width: 600px;
  max-width: fit-content;
  height: fit-content;
  z-index: 10;
  top: 100%;
}
.profileviw {
  right: 0%;
}
.cate {
  color: #f7eded75;
  padding: 5px;
}

.cate:hover {
  background-color: white;
  border-radius: 5px;
  width: auto;
  color: black;
  font-weight: 500;
}
.videoWidth {
  width: 100%;
  height: 110vh;
}

.informat,
.informaticon,
.alldata,
.playlis,
.playlisa,
.progresbr {
  position: absolute;
  top: 10%;
  z-index: 1;
  color: white;
}
.plyr__control--overlaid {
  position: relative !important;
  z-index: 100 !important;
  background-color: red !important;
}
.informat .row {
  z-index: 2;
}

.playlisa {
  right: 0% !important;
}
.informat {
  top: 30%;
}

.informat1 {
  top: 40%;
}
.informaticon {
  top: 50% !important;
}
.progresbr {
  top: 85%;
  left: 3%;
}
.ytp-menuitem-label:contains("Copy video URL"),
.ytp-menuitem-label:contains("Loop") {
  display: none !important;
}

.ytp-menuitem[ytp-aria-checked="true"],
.ytp-button:not([aria-disabled="true"]):not([disabled]):not(
    [aria-hidden="true"]
  ),
.ytp-large-play-button {
  display: none !important;
}
.ytp-title-link .yt-uix-sessionlink {
  display: none;
}
.langauge {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
}
.form-select,
.form-control {
  border: 1px solid #2c2e33 !important;
  font-weight: 400 !important;
  padding: 0.375rem 0.6875rem !important;
  background-color: rgba(128, 128, 128, 0.637) !important;
  border-radius: 2px !important;
  color: white !important;
}
.card:hover {
  background-color: #f0f0f098;
}

.react-multiple-carousel__arrow {
  min-height: 100px !important;
  min-width: 25px !important;
  border-radius: 5px;
}
.react-multiple-carousel__arrow--right {
  right: 0% !important;
}
.react-multiple-carousel__arrow--left {
  left: 0% !important;
}

.mediaHoverImg {
  height: 160px;
  width: 300px;
  margin-top: 10px;
  border-radius: 0%;
  z-index: 2;
}

.hoverScreen {
  position: relative;
  color: white;
  width: 300px;
  transform: scale(1.1);
  transition: transform 3s 2000ms;
  top: -30px;
  opacity: 1;
  z-index: 2;
  margin-bottom: 20px;
  text-decoration: none;
}

.hoverData {
  position: relative;
  background: linear-gradient(rgba(27, 37, 48, 0), #1b2530 48px);
  padding: 30px 30px;
  padding-bottom: 5px;
  margin-top: -45px;
  z-index: 2;
  text-decoration: none;
  display: none;
}

.mediaHoverImg:hover + .hoverData {
  display: block !important;
}

.footerScreen {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  /* width: 200px; */
}

.mediaDiv {
  position: static;
  width: 100%;
  cursor: pointer;
  z-index: 0;
  padding: 10px 0px;
  padding-top: 5px;
}
.hoverScreen:hover .hoverData {
  display: block;
}

.hoverData {
  display: none;
}

.mediaDiv:hover .displayhoverScreen {
  display: block !important;
}

.displayhoverScreen {
  display: none;
  position: absolute;
  z-index: 5;
  padding: 1px;
  min-height: 400px;
  transform: scale(1);
}

.mediaImg {
  height: 160px;
  object-fit: cover;
  width: 300px;
  margin: 5px;
  border-radius: 4px;
  border-radius: 10px;
  z-index: 0;
  visibility: visible;
}

.mediaDiv:hover .mediaImg,
.mediaDiv:hover .controls {
  height: 0px;
  width: 284px;
  visibility: hidden;
}

.hoverData .playDiv {
  display: flex;
  align-items: center;
}

.playtext {
  text-decoration: none;
  margin-top: 5px;
  font-size: 14px;
  font-weight: bold;
}
.banner {
  display: flex;
  left: 10px;
  overflow-y: hidden;
  overflow-x: hidden;
  scroll-behavior: smooth;
  z-index: -1;
}

.searchBox:hover > .searchInput {
  width: 240px;
  padding: 0 6px;

  border-radius: 100px !important;
}

.searchBox:hover > .searchButton {
  color: white;
  color: #2f3640;
}

.searchButton {
  color: white;
  float: right;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #bdc3ca00;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
}

.searchInput {
  border: none;
  background: none;
  outline: none;
  float: left;
  padding: 0;
  color: white;
  font-size: 16px;
  transition: 0.4s;
  line-height: 40px;
  width: 0px;
}

@media screen and (max-width: 620px) {
  .searchBox:hover > .searchInput {
    width: 150px;
    padding: 0 6px;
  }
}

.searchBox {
  position: relative;
  background: #2f3640;
  height: 40px;
  border-radius: 40px;
  padding: 2px;
}
.bg-emerald-600 {
  background-color: rgb(12, 74, 165);
}

/* Default styles */
.carousel {
  width: 100%; /* Takes up full width by default */
}

/* Media query for small devices (phones, etc.) */
@media screen and (max-width: 576px) {
  .carousel {
    /* Adjust styles for smaller screens */
    width: 100%; /* Take up full width */
  }
}

/* Media query for medium devices (tablets, etc.) */
@media screen and (min-width: 577px) and (max-width: 992px) {
  .carousel {
    /* Adjust styles for medium screens */
    width: 80%; /* Take up 80% of the width */
  }
}

/* Media query for large devices (laptops, desktops, etc.) */
@media screen and (min-width: 993px) {
  .carousel {
    /* Adjust styles for larger screens */
    width: 70%; /* Take up 70% of the width */
  }
}
.text_light {
  color: rgb(203, 194, 194);
}
.custom-modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

.modal_header {
  text-align: center;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 24px;
  margin-bottom: 5px;
}

.modal-subtitle {
  font-size: 16px;
  color: #999;
}

.modal-body {
  display: flex;
  flex-direction: column; /* Change to column layout */
  align-items: center; /* Center content horizontally */
}

.pricing-plan {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Display cards in four columns */
  gap: 20px; /* Add gap between cards */
  justify-content: center; /* Center cards horizontally */
}

.plan {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.plan:hover {
  transform: translateY(-5px); /* Add hover effect */
}

.price {
  margin-bottom: 20px;
}

.price h5 {
  font-size: 24px;
}

.price h6 {
  font-size: 16px;
  color: #999;
}

.period {
  font-size: 14px;
  color: #f8f3f3;
}

.savings {
  font-size: 14px;
  color: #00a0e3;
  margin-bottom: 20px;
}

.subscribe-button {
  background-color: #00a0e3;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #0079a7;
}
.catecoulme {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 2px;
  /* padding: 20px; */
}

.slick-dots li button,
.slick-dots {
  color: rgb(251, 245, 245) !important;
}
.slick-dots li.slick-active button:before {
  color: red !important;
  font-size: 10px !important;
}
.slick-dots li button:before {
  color: rgb(251, 245, 245) !important;
  font-size: 10px !important;
  opacity: 1 !important;
}
.clubtitle {
  color: rgba(111, 97, 16, 0.534);
  font-weight: bold;
}

.club {
  background-color: rgb(63, 62, 62);
  margin: 5px;
  border-radius: 10px;
  box-shadow: 4px 5px 5px;
}
.history-gry {
  background-color: rgba(255, 255, 255, 0.167);
  /* opacity: 0.9; */
}
.color-re {
  color: rgba(255, 0, 0, 0.724) !important;
  font-weight: bold;
  font-size: 25px;
}

.expired-item {
  filter: blur(1px);
  opacity: 0.9;
  transition: filter 0.3s, opacity 0.3s;
}


@media only screen and (min-width: 1200px) {
  .banner {
    justify-content: space-around; 
  }
  .mediaDiv {
    flex: 0 0 22%; 
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mediaDiv {
    flex: 0 0 30%; 
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mediaDiv {
    flex: 0 0 45%; 
  }
}

@media only screen and (max-width: 767px) {
  .mediaDiv {
    flex: 0 0 90%;
  }
}
